import React from 'react';
import { connect } from 'react-redux';
import { Modal, message } from 'antd';
import store from '../store';
import { activePracticeDuck, loadingDuck, drawerOpenDuck } from '../ducks/root';
import * as API from '../api';

class CreateNew extends React.Component {
  state = { show: false, code: '' };

  render() {
    return (
      <div style={this.props || {}}>
        <Modal
          title="Enter access code"
          visible={this.state.show}
          onCancel={() => {
            this.setState({ show: false });
          }}
          onOk={async () => {
            store.dispatch(
              loadingDuck.set({
                show: true,
                text: 'Requesting access...',
              })
            );
            try {
              let resp = await API.post(['requestAccess'], {
                code: this.state.code,
              });
              let pracKey = resp.data.message;

              message.success('Access granted.');
              store.dispatch(activePracticeDuck.set(pracKey));
              store.dispatch(drawerOpenDuck.clear());
              this.setState({ show: false, code: '' });
            } catch (err) {
              console.log(err);
              message.error(err.message);
            }
            store.dispatch(loadingDuck.clear());
          }}
        >
          <p>
            <label className="cstm">Access code</label>
          </p>
          <input
            className="cstm"
            type="text"
            placeholder="Enter access code here"
            onChange={evt => {
              this.setState({ code: evt.target.value });
            }}
          />
        </Modal>
        <div
          onClick={() => {
            this.setState({ show: true });
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mstp = state => ({
  uid: state.uid,
});
export default connect(mstp)(CreateNew);
