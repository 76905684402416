import React from 'react';
import { connect } from 'react-redux';
import * as globals from '../globals';

import store from '../store';
import { mapperDuck } from '../ducks/root';

class MapperDataManager extends React.Component {
  state = {
    listeners: [],
  };

  createListeners(perms) {
    if (!perms || !Object.keys(perms).length) return;
    for (let siteKey in perms) {
      let pubPath = `practices/${siteKey}`;
      globals.db.child(pubPath).on('value', snap => {
        if (!snap || !snap.exists()) return;
        store.dispatch(mapperDuck.update(['practices', siteKey], snap.val()));
      });

      let privPath = `private/practices/${siteKey}`;
      globals.db.child(privPath).on('value', snap => {
        if (!snap || !snap.exists()) return;
        store.dispatch(
          mapperDuck.update(['private', 'practices', siteKey], snap.val())
        );
      });

      this.setState({
        listeners: {
          ...this.state.listeners,
          [privPath]: true,
          [pubPath]: true,
        },
      });
    }
  }

  clearListeners() {
    for (let path in this.state.listeners) {
      globals.db.child(path).off();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.admin === true) {
      console.log('is admin...clearing MapperDataManager');
      this.clearListeners();
      return false;
    }
    if (this.props.permissions === nextProps.permissions) return false;
    this.clearListeners();
    this.createListeners(nextProps.permissions);
    return true;
  }

  componentDidMount() {
    this.clearListeners();
    this.createListeners(this.props.permissions);
  }

  componentWillUnmount() {
    this.clearListeners();
  }

  render() {
    return null;
  }
}

const mstp = state => ({
  permissions: state.permissions,
  admin: state.admin,
});
export default connect(mstp)(MapperDataManager);
