import React from 'react';
import { connect } from 'react-redux';

import FireInput from './FireInput';
import schema from '../mapperSchema';

class MapperDash extends React.Component {
  render() {
    const { activePractice } = this.props;

    return schema.map((item, i) => {
      let baseKeys = [
        item.private ? 'private' : '',
        'practices',
        // 'activePracticeData',
        activePractice,
      ].filter(x => x);

      return (
        <FireInput root={true} key={i} branch={baseKeys} schema={item} />
      );
    });
  }
}

const mstp = state => ({
  uid: state.uid,
  activePractice: state.activePractice,
});
export default connect(mstp)(MapperDash);
