import React from 'react';

const Spacer = props => {
  let style = {};
  if (props.size) {
    let sizeMap = {
      none: '0vw',
      xsmall: '0.5vw',
      small: '1vw',
      medium: '2vw',
      large: '4vw',
      xlarge: '8vw',
      xxlarge: '18vw',
    };
    if (!sizeMap[props.size]) {
      throw new Error('Invalid `size` prop passed to Spacer.');
    }
    style = {
      ...style,
      height: sizeMap[props.size] || '0px',
      width: sizeMap[props.size] || '0px',
    };
  }
  if (props.flex) {
    style = { ...style, flex: props.flex === true ? 1 : props.flex };
  }
  if (props.h) style = { ...style, height: props.h }
  if (props.w) style = { ...style, width: props.w }
  return <div style={style} />;
};

export default Spacer;
