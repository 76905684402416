const UPDATE = 'UPDATE';
const CLEAR = 'CLEAR';

export const update = (keys, data) => ({
  type: UPDATE,
  payload: {
    keys: keys,
    data: data,
  },
});

export const clear = (keys, data) => ({
  type: CLEAR,
});

const initialState = null;
const reducer = (state = initialState, action) => {
  // console.log("initialState: " + JSON.stringify(initialState, null, 2));
  // console.log("action: " + JSON.stringify(action, null, 2));

  const payload = action.payload || {};
  const { keys, data } = payload;

  switch (action.type) {
    case CLEAR:
      return null;

    case UPDATE:
      const dive = (keys, state, data) => {
        if (keys.length === 0) {
          return data;
        }

        let key = keys.shift(0);
        if (typeof key === 'string') {
          state = state || {};
          const childState = state[key];
          return {
            ...state,
            [key]: dive(keys, childState, data),
          };
        } else if (typeof key === 'number') {
          state = state || [];
          const childState = state[key];

          if (key === state.length) {
            return [...state, dive(keys, childState, data)];
          } else if (key >= 0 && key < state.length) {
            return state.map((el, i) => {
              if (i !== key) {
                return el;
              } else {
                return dive(keys, childState, data);
              }
            });
          } else {
            alert(
              'Invalid array index. Existing array only has ' +
                state.length +
                ' elements. Cannot insert at index ' +
                key +
                '.'
            );
            return;
          }
        } else {
          throw new Error('Unknown type: ' + key);
        }
      };

      let result = dive(keys, state, data);
      return result;

    default:
      return state;
  }
};

export default reducer;
