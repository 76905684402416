import React from 'react';

import { Button } from 'antd';
import * as globals from '../globals';
function MapperProfileButton(props) {
  return (
    <Button
      href={globals.mapperURL + '/practice/' + props.value}
      target="_blank"
      type="primary"
      size="small"
    >
      Open mapper profile in new tab
    </Button>
  );
}

MapperProfileButton.propTypes = {};

export default MapperProfileButton;
