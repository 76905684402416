import React from 'react';
import { connect } from 'react-redux';
import * as globals from '../globals';
import { message } from 'antd';

import store from '../store';
import {
  uidDuck,
  permissionsDuck,
  activePracticeDuck,
  userDataDuck,
  usersDuck,
  allPermsDuck,
  // adminsDuck,
  adminDuck,
  mapperDuck,
} from '../ducks/root';

class AuthManager extends React.Component {
  componentDidMount() {
    this.unregisterAuthObserver = globals.auth.onAuthStateChanged(
      async user => {
        if (this.cancelPermsListener) this.cancelPermsListener();
        if (this.cancelUserListener) this.cancelUserListener();

        if (user === null) {
          store.dispatch(uidDuck.clear());
          store.dispatch(userDataDuck.clear());
          console.log('no user found');
          return;
        }

        store.dispatch(uidDuck.set(user.uid));
        try {
          this.cancelUserListener = await globals.db
            .child(`users/${user.uid}`)
            .on('value', async userSnap => {
              if (!userSnap) return;
              const userData = userSnap.exists() ? userSnap.val() : {};
              store.dispatch(userDataDuck.set(userData));
            });

          const adminSnap = await globals.db.child(`admins`).once('value');
          if (adminSnap && adminSnap.exists()) {
            let admins = adminSnap.val();

            if (admins[user.uid] === true) {
              store.dispatch(adminDuck.set(true));
              console.log('ADMIN!');
              globals.db.child(`practices`).on('value', snap => {
                if (!snap || !snap.exists()) return;

                console.log('fetched all practices!');
                let practices = snap.val();
                let perms = {};
                for (let pid in practices) {
                  perms[pid] = true;
                }

                // old
                // store.dispatch(mapperDuck.update(['practices'], practices));
                // new
                const currPractices = this.props.practices || {};
                const updatedPractices = {};
                for (let pid in practices) {
                  updatedPractices[pid] = {
                    ...(currPractices[pid] || {}),
                    ...practices[pid],
                  };
                }
                // console.log(`updating ${pid}...`);
                store.dispatch(
                  mapperDuck.update(['practices'], updatedPractices)
                );

                // set permissions
                console.log('setting permissions');
                store.dispatch(permissionsDuck.set(perms));

                // let firstPid = Object.keys(perms)[0];
                // store.dispatch(activePracticeDuck.set(firstPid));
              });

              globals.db.child(`private/practices`).on('value', snap => {
                if (!snap || !snap.exists()) return;
                console.log('fetched all private practices...');
                let privPractices = snap.val();
                store.dispatch(
                  mapperDuck.update(['private', 'practices'], privPractices)
                );
              });

              globals.db.child(`users`).on('value', snap => {
                if (!snap || !snap.exists()) return;
                console.log('fetched all users...');
                let users = snap.val();
                store.dispatch(usersDuck.set(users));
              });

              globals.db.child(`permissions`).on('value', snap => {
                if (!snap || !snap.exists()) return;
                console.log('fetched all perms...');
                let allPerms = snap.val();
                store.dispatch(allPermsDuck.set(allPerms));
              });
              return;
            }
          }

          this.cancelPermsListener = await globals.db
            .child('permissions/' + user.uid)
            .on('value', async permsSnap => {
              if (
                !permsSnap ||
                !permsSnap.exists() ||
                permsSnap.val() === null
              ) {
                store.dispatch(permissionsDuck.clear());
                store.dispatch(activePracticeDuck.clear());
                return;
              }

              let perms = await permsSnap.val();
              store.dispatch(permissionsDuck.set(perms));

              if (!this.props.activePractice) {
                let firstPid = Object.keys(perms)[0];
                store.dispatch(activePracticeDuck.set(firstPid));
              } else {
                console.log('not overwriting active entity');
              }
            });
        } catch (err) {
          console.log(err);
          message.error(err.message);
        }
      }
    );
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
    if (this.cancelUserListener) this.cancelUserListener();
    if (this.cancelPermsListener) this.cancelPermsListener();
  }
  render() {
    return null;
  }
}

const mstp = state => ({
  activePractice: state.activePractice,
  practices: (state.mapper || {}).practices,
});
export default connect(mstp)(AuthManager);
