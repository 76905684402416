import React from 'react';
import { connect } from 'react-redux';

import MapperDash from './MapperDash';
import AdminDash from './AdminDash';

class DashboardContent extends React.Component {
  render() {
    if (this.props.activeSidebarItem === 'mapper') {
      let showAdmin = this.props.admin === true && !this.props.activePractice;
      let content = showAdmin ? <AdminDash /> : <MapperDash />;

      return (
        <div className="contentwrapper col wide">
          <div id={this.props.id} className="content wide">
            {content}
            <br />
            <br />
            <br />
          </div>
        </div>
      );
    }
    return null;
  }
}

const mstp = state => ({
  activeSidebarItem: state.activeSidebarItem,
  admin: state.admin,
  activePractice: state.activePractice,
});
export default connect(mstp)(DashboardContent);
