import React from 'react';
import { Column } from './Flex';
import { connect } from 'react-redux';
import { Icon, Anchor } from 'antd';

import * as util from '../util';
// import '../style/Sidebar.scss';
import sassVars from '../style/_common.scss';
import store from '../store';
import { activeSidebarItemDuck } from '../ducks/root';

import mapperSchema from '../mapperSchema';

const { Link } = Anchor;

const SidebarItem = ({ id, icon, text }) => {
  return (
    <div
      className={'sidebaritem'}
      onClick={() => {
        store.dispatch(activeSidebarItemDuck.set(id));
      }}
    >
      <Icon type={icon} theme="twoTone" twoToneColor={sassVars.blueColor} />
      <p>{text}</p>
    </div>
  );
};

class Sidebar extends React.Component {
  render() {
    const { activeSidebarItem } = this.props;
    const anchorLinks = mapperSchema
      .map((field, i) => {
        if (field.type !== 'header') return null;
        return [
          <Link
            href={`#${util.slugify(field.text)}`}
            key={i}
            title={field.text}
          />,
        ];
      })
      .filter(x => x !== null);

    let anchorProps = {};
    let scrollNode = document.getElementById(this.props.id);
    if (scrollNode)
      anchorProps['getContainer'] = () => {
        return scrollNode;
      };
    return (
      <div className="sidebar">
        <Column align="flex-start">
          <SidebarItem icon="environment" text="Mapper" id="mapper" />
          {activeSidebarItem === 'mapper' && (
            <Anchor soffsetTop={110} {...anchorProps}>
              {anchorLinks}
            </Anchor>
          )}
          {false && <SidebarItem icon="idcard" text="Hiring" id="hiring" />}
        </Column>
      </div>
    );
  }
}

const mstp = state => ({
  activeSidebarItem: state.activeSidebarItem,
});
export default connect(mstp)(Sidebar);
