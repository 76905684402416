import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import save from '../save';

class MapperSaveButton extends React.PureComponent {
  state = { loading: false, lastSavedHash: null, saveState: 'saved' };

  componentDidMount() {
    // setInterval(this.checkHash, 3000);
  }

  // checkHash = async () => {
  //   console.log('checking save hashes');
  //   const { mapper } = this.props;
  //   const newhash = hash(mapper);
  //   const lasthash = this.state.lastSavedHash;
  //   console.log(newhash);
  //   console.log(lasthash);
  //   if (lasthash === null) {
  //     this.setState({ lastSavedHash: newhash });
  //     return;
  //   }

  //   if (lasthash !== newhash) {
  //     this.setState({ saveState: 'saveRequired' });
  //   }
  // };

  // save = async () => {
  //   const { mapper } = this.props;
  //   const { practices, private: privatePractices } = mapper;
  //   try {
  //     let pubUpdates = {};
  //     let invalids = [];

  //     this.setState({ loading: true });

  //     for (let pracKey in practices) {
  //       let pracData = practices[pracKey];
  //       pubUpdates[pracKey] = pracData;
  //       pubUpdates[pracKey] = pracData;
  //     }
  //     if (invalids.length > 0) {
  //       message.error(
  //         `Save failed! Some practices are missing required fields (name and website): ${invalids.join(
  //           ', '
  //         )}`
  //       );
  //       this.setState({ loading: false });
  //       return;
  //     }

  //     let p1 = globals.db.child('practices').update(pubUpdates);
  //     let p2 = globals.db
  //       .child('private/practices')
  //       .update(privatePractices.practices);
  //     let proms = [p1, p2];
  //     await Promise.all(proms);
  //     // message.success('Saved.');
  //   } catch (err) {
  //     message.error('Error occurred: ' + err.message);
  //   }
  //   this.setState({ loading: false });
  // };

  render() {
    // const { mapper } = this.props;
    // const { practices, private: privatePractices } = mapper;

    return (
      <Button
        type="primary"
        size={'large'}
        icon="save"
        style={{ minWidth: '250px' }}
        loading={this.state.loading}
        onClick={async () => {
          this.setState({ loading: true });
          await save();
          this.setState({ loading: false });
        }}
      >
        {this.state.saveState === 'saved'
          ? 'Save'
          : 'Changes detected. Click to save.'}
      </Button>
    );
  }
}
const mstp = state => ({
  mapper: state.mapper,
});
export default connect(mstp)(MapperSaveButton);
