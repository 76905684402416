import React from 'react';

import { connect } from 'react-redux';
import * as _ from 'lodash';

class BaseUniversal extends React.PureComponent {
  render() {
    let Comp = this.props.component;
    let { practiceData, privatePracticeData, activePractice } = this.props;

    return (
      <Comp
        data={practiceData}
        privateData={privatePracticeData}
        activePractice={activePractice}
      />
    );
  }
}

const mstp = state => {
  // console.log('ap: ' + state.activePractice);
  if (!state.activePractice) {
    console.log('no ap');
    return {};
  }
  let activePractice = state.activePractice;
  let practiceData = _.get(state, ['mapper', 'practices', activePractice]);
  let privatePracticeData = _.get(state, [
    'mapper',
    'private',
    'practices',
    activePractice,
  ]);
  // console.log(practiceData);
  return { practiceData, privatePracticeData, activePractice };
};

const Universal = connect(mstp)(BaseUniversal);
export default Universal;
