export const getUsersByPractice = (users, permissions) => {
  if (!users) return {};
  if (!permissions) return {};

  const usersByPractice = {};
  for (let uid in users) {
    let email = users[uid].email;
    if (!email) continue;

    try {
      const permRecord = permissions[uid];
      const userRecord = users[uid];
      const userName = [userRecord.first, userRecord.last].join(' ').trim();

      for (let pracId in permRecord) {
        usersByPractice[pracId] = usersByPractice[pracId] || [];
        usersByPractice[pracId].push({
          uid: uid,
          email: email,
          name: userName,
        });
      }
    } catch (err) {
      console.log('ERROR');
      console.log(err);
    }
  }
  return usersByPractice;
};
