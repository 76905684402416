import axios from 'axios';
import * as globals from './globals';
import * as util from './util';

const getToken = () => {
  let user = globals.auth.currentUser;
  if (!user) {
    return new Promise((res, rej) => rej);
  }
  return user.getIdToken(true);
};

export const request = async (method, path, data) => {
  // try {
  const token = await getToken();
  let url = globals.serverURL + path;
  let options = {
    method,
    url,
    data,
    headers: {
      Authorization: token,
      'Access-Control-Allow-Origin': '*',
    },
  };
  return axios(options);
  // return result;
  // return new Promise((res, rej) => {
  //   res(result.data);
  // });
  // } catch (err) {
  //   return new Promise((res, rej) => {
  //     rej(err);
  //   });
  // }
};

export const post = async (path, data) => {
  return request('post', path.join('/'), data);
};

export const get = async (path, data) => {
  return request('get', path.join('/') + '?' + util.serialize(data), data);
};

export const put = async (path, data) => {
  return request('put', path.join('/'), data);
};

export const patch = async (path, data) => {
  return request('patch', path.join('/'), data);
};

export const del = async (path, data) => {
  return request('delete', path.join('/'), data);
};
