import React from 'react';
import { connect } from 'react-redux';
import { Drawer } from 'antd';

import UserMenu from './UserMenu';
import { Row } from './Flex';
import PracticeDropdown from './PracticeDropdown';
import Sidebar from './Sidebar';
import IconCircle from './IconCircle';
import store from '../store';
import { drawerOpenDuck } from '../ducks/root';

class MobileMenu extends React.Component {
  render() {
    return (
      <div className="dashheader wide">
        <Row justify="flex-start">
          <IconCircle
            icon="menu"
            iconProps={{ theme: 'outlined' }}
            px={40}
            onClick={() => {
              store.dispatch(drawerOpenDuck.set(!this.props.drawerOpen));
            }}
          />
          <div style={{ flex: 1 }} />
          <UserMenu />
        </Row>
        <Drawer
          title="Menu"
          placement={'left'}
          closable={true}
          onClose={() => {
            store.dispatch(drawerOpenDuck.clear());
          }}
          visible={this.props.drawerOpen}
        >
          <PracticeDropdown />
          <Sidebar id="#mobiledash" />
        </Drawer>
      </div>
    );
  }
}

const mstp = state => ({
  drawerOpen: state.drawerOpen,
});
export default connect(mstp)(MobileMenu);
