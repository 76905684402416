import React from "react";
import _ from "lodash";
import { message, Button, Modal } from "antd";
import { connect } from "react-redux";
import store from "../store";
import * as globals from "../globals";
import * as util from "../util";
import { getUsersByPractice } from "../getUsersByPractice";
import { activePracticeDuck } from "../ducks/root";
import Spacer from "./Spacer";

const MenuP = props => (
  <div style={{ padding: "12px 0px" }}>{props.children}</div>
);

class PracticeDropdown extends React.Component {
  state = {};

  render() {
    // console.log(this.props.users);
    // console.log(this.props.allPerms);
    const usersByPractice = getUsersByPractice(
      this.props.users,
      this.props.allPerms
    );
    // console.log(usersByPractice);

    this.cache = this.cache || {};

    const practiceObj = this.props.practices || {};
    let allKeys = Object.keys(practiceObj);
    let sortedPracs;

    if (!this.cache[allKeys.length]) {
      console.log("sorting pracs");

      const allPracs = [];
      for (const pracId in practiceObj) {
        const prac = practiceObj[pracId];
        
        allPracs.push({
          ...prac,
          name: prac.name || "<Unnamed>",
          pid: pracId
        });
        
      }
      sortedPracs = allPracs.sort((x, y) => {
        return ("" + x.name).localeCompare(y.name);
      });
      this.cache[allKeys.length] = sortedPracs;
    } else {
      console.log("returning cached sorted list");
      sortedPracs = this.cache[allKeys.length];
    }

    const menu = (
      <div className="fill">
        {sortedPracs.map((prac, i) => {
          const flags = [];

          if (prac.deadWebsite) flags.push("Dead website");
          // if (!prac.openDate) flags.push("Missing start date");
          if (!prac.prices || !prac.prices.length) flags.push("Missing prices");
          if (!prac.people || !prac.people.length) flags.push("Missing staff");

          if (
            !prac.dispensing &&
            !prac.labDiscounts &&
            !prac.radiologyDiscounts &&
            !prac.messageCommunication &&
            !prac.emailCommunication &&
            !prac.cellCommunication &&
            !prac.homeVisits
          )
            flags.push("No perks");
          // console.log(`${prac.name}: ${prac.pid}`);

          return (
            <MenuP pid={prac.pid}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <p style={{ lineHeight: 1, margin: 0 }}>
                  {prac.name || "Unnamed"}
                </p>
                {prac.verified === "rejected" && (
                  <small
                    style={{
                      color: "red",
                      fontStyle: "italic",
                      paddingLeft: "8px"
                    }}
                  >
                    Rejected
                  </small>
                )}
                {prac.verified === "pending" && (
                  <small
                    style={{
                      color: "red",
                      fontStyle: "italic",
                      paddingLeft: "8px"
                    }}
                  >
                    PENDING VERIFICATION
                  </small>
                )}
                {(prac.verified === undefined || prac.verified === false) && (
                  <small
                    style={{
                      color: "red",
                      fontStyle: "italic",
                      paddingLeft: "8px"
                    }}
                  >
                    NOT SUBMITTED
                  </small>
                )}
                {prac.practiceStatus === "closed" && (
                  <small
                    style={{
                      color: "red",
                      fontStyle: "italic",
                      paddingLeft: "8px"
                    }}
                  >
                    CLOSED
                  </small>
                )}
              </div>
              {prac.address && prac.address.line1 && (
                <p style={{ lineHeight: 1, margin: 0 }}>
                  <small>{`${prac.address.line1} ${prac.address.line2 ||
                    ""}`}</small>
                  <br />
                  <small>{`${prac.address.city}, State: ${
                    prac.address.region
                  }`}</small>
                </p>
              )}
              {usersByPractice[prac.pid] && (
                <p style={{ lineHeight: 1, margin: 0 }}>
                  <small>
                    {usersByPractice[prac.pid]
                      .map(user => {
                        return `${user.email}${
                          user.name ? ` (${user.name})` : ""
                        }`;
                      })
                      .join(", ")}
                  </small>
                </p>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "3"
                }}
              >
                <Button
                  size="small"
                  onClick={evt => {
                    if (evt && evt.stopPropagation) evt.stopPropagation();
                    store.dispatch(activePracticeDuck.set(prac.pid));
                    message.success("Switched practice.");
                    return false;
                  }}
                >
                  open profile
                </Button>
                <Spacer w="4px" />
                <Button
                  loading={this.state[`duplicating_${prac.pid}`]}
                  size="small"
                  onClick={async e => {
                    console.log(`duplicating`);
                    e.stopPropagation();
                    this.setState({
                      [`duplicating_${prac.pid}`]: true
                    });

                    let entityRef = globals.db.child("practices").push();
                    let privEntityRef = globals.db.child(
                      `private/practices/${entityRef.key}`
                    );

                    console.log(`created prac ${entityRef.key}`);

                    // IMPORTANT
                    // must write to permissions
                    // BEFORE writing any data to entityRef
                    const users = usersByPractice[prac.pid] || [];
                    console.log("users");
                    console.log(users);
                    for (const user of users) {
                      console.log(
                        `setting perm for ${user.uid}.${entityRef.key}`
                      );

                      await globals.db
                        .child("permissions")
                        .child(user.uid)
                        .child(entityRef.key)
                        .set(true);
                    }

                    console.log("set permissions!");
                    let id = util.randomString(12);
                    console.log(`creating!! ${id}`);
                    const now = new Date();
                    const pracData = {
                      ...prac,
                      pid: null,
                      id: id,
                      createdAt: now.toISOString()
                    };
                    console.log(JSON.stringify(pracData, null, 2));
                    await entityRef.set(pracData);
                    const privData = {
                      accessCode: util.randomString(20),
                      id: id
                    };
                    console.log(JSON.stringify(privData, null, 2));
                    await privEntityRef.set(privData);

                    this.setState({
                      [`duplicating_${prac.pid}`]: false
                    });
                    message.info("Practice duplicated.");
                    store.dispatch(activePracticeDuck.set(entityRef.key));
                    return false;
                  }}
                >
                  duplicate
                </Button>
                <Spacer w="4px" />
                <Button
                  type="danger"
                  loading={this.state[`deleting_${prac.pid}`]}
                  size="small"
                  onClick={async e => {
                    e.stopPropagation();
                    this.setState({ [`deleting_${prac.pid}`]: true });
                    Modal.confirm({
                      title: "Are you sure you want to delete this practice?",
                      onOk: async e => {
                        console.log("okOk");
                        // e.preventDefault();
                        this.setState({
                          [`deleting_${prac.pid}`]: true
                        });
                        console.log(`deleting ${prac.pid}`);
                        if (prac.pid) {
                          const entityRef = globals.db
                            .child("practices")
                            .child(prac.pid);
                          console.log("deleting...");
                          await entityRef.set(null);
                          console.log("deleted.");
                        }
                        this.setState({
                          [`deleting_${prac.pid}`]: false
                        });
                        // e.stopPropagation();
                      },
                      onCancel: () => {
                        this.setState({
                          [`deleting_${prac.pid}`]: false
                        });
                      }
                    });
                  }}
                >
                  delete
                </Button>
              </div>
              <div>
                {flags.map(flag => (
                  <p style={{ lineHeight: 1, margin: 0, color: "red" }}>
                    <small>{flag}</small>
                  </p>
                ))}
              </div>
              {false && (
                <p
                  style={{ lineHeight: 1, margin: 0 }}
                  onClick={e => e.stopPropagation()}
                >
                  <small>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://console.firebase.google.com/u/0/project/dpcmapper/database/dpcmapper/data/practices/${
                        prac.pid
                      }`}
                    >
                      edit
                    </a>
                  </small>
                </p>
              )}
            </MenuP>
          );
        })}
      </div>
    );

    return (
      <div className="fill">
        <div className="card">
          <h4>Admin dashboard</h4>
          <p>Click a practice to modify its information</p>
        </div>
        {menu}
      </div>
    );
    // return (
    //   <Dropdown
    //     overlay={
    //       <div style={{ maxHeight: '600px', overflow: 'scroll' }}>{menu}</div>
    //     }
    //     visible={this.state.showDropdown}
    //     onVisibleChange={flag => {
    //       this.setState({ showDropdown: flag });
    //     }}
    //   >
    //     <div style={{ cursor: 'pointer' }}>
    //       <Row justify="start">
    //         <IconCircle icon="shop" />
    //         {leaf}
    //         <Icon type="down" />
    //       </Row>
    //     </div>
    //   </Dropdown>
    // );
  }
}

const mstp = state => ({
  permissions: state.permissions,
  users: state.users,
  allPerms: state.allPerms,
  practices: (state.mapper || {}).practices,
  privatePractices: _.get(state.mapper, "private.practices") || {},
  activePractice: state.activePractice
});
export default connect(mstp)(PracticeDropdown);
