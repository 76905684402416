import React from 'react';
import { connect } from 'react-redux';
import { Spin, Button } from 'antd';

import Dashboard from '../components/Dashboard';
import LoginForm from '../components/LoginForm';
import SetPassword from '../components/SetPassword';
import WaitForVerification from '../components/WaitForVerification';
import CreateNew from '../components/CreateNew';
import JoinExisting from '../components/JoinExisting';

import * as globals from '../globals';
// import '../style/DashboardPage.scss';

class DashboardPage extends React.Component {
  render() {
    const spinner = (
      <div className="fullpage">
        <Spin />
      </div>
    );
    if (this.props.uid === null) {
      console.log('if (this.props.uid === null) {');
      return spinner;
    }

    let user = globals.auth.currentUser;
    if (this.props.uid === '' || !user) {
      console.log("if (this.props.uid === '') {");
      return (
        <div className="fullpage">
          <LoginForm />
        </div>
      );
    }

    if (user.emailVerified !== true) {
      console.log('Email not verified');
      return (
        <div className="fullpage">
          <WaitForVerification />
        </div>
      );
    }
    if (this.props.userData === null) {
      console.log('No user data');
      return spinner;
    }

    if (this.props.userData.passwordSet === false) {
      console.log('Password not set');
      return (
        <div className="fullpage">
          <SetPassword />
        </div>
      );
    }

    const perms = this.props.permissions;
    if (perms === null) {
      console.log('Permissions is null');
      return spinner;
    }

    if (typeof perms === 'object' && Object.keys(perms).length === 0) {
      console.log('No permissisons');
      return (
        <div className="fullpage">
          <CreateNew>
            <Button>
              <p style={{ margin: '0px' }}>Create your first practice</p>
            </Button>
          </CreateNew>
          <br />
          <JoinExisting>
            <Button>
              <p style={{ margin: '0px' }}>Join existing practice</p>
            </Button>
          </JoinExisting>
        </div>
      );
    }

    if (this.props.mapperExists === false) {
      console.log("mapper doesn't exist");
      return spinner;
    }
    console.log('rendering Dashboard');
    return <Dashboard />;
  }
}

const mstp = state => {
  let mapperExists = state.mapper !== null && state.mapper !== undefined;
  return {
    uid: state.uid,
    permissions: state.permissions,
    mapperExists: mapperExists,
    userData: state.userData,
    admin: state.admin,
    activePractice: state.activePractice,
  };
};

export default connect(mstp)(DashboardPage);
