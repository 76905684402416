import React from 'react';
import { Icon } from 'antd';

import sassVars from '../style/_common.scss';
// import '../style/IconCircle.scss';

const IconCircle = props => {
  let theme = props.theme || 'twoTone';
  let px = props.px || 40;
  return (
    <div
      className="iconcircle"
      style={{
        width: `${px}px`,
        height: `${px}px`,
        borderRadius: `${px / 2}px`,
      }}
      onClick={props.onClick}
    >
      <Icon
        type={props.icon}
        theme={theme}
        twoToneColor={sassVars.blueColor}
        style={{ fontSize: `${px * 0.6}px` }}
        {...props.iconProps}
      />
    </div>
  );
};

export default IconCircle;
