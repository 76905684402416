import { combineReducers } from 'redux';
import meta from './meta';
import mapperReducer, * as mapperDuck from './mapperDuck';

export const uidDuck = meta('uid', null, '');
export const userDataDuck = meta('userData', null, {});
export const adminsDuck = meta('admin', null, {});
export const usersDuck = meta('users', null, {});
export const allPermsDuck = meta('allperms', null, {});
export const adminDuck = meta('admin', null, false);
export const permissionsDuck = meta('permissions', null, {});
export const activePracticeDuck = meta('active_practice', null, null);
// export const activePracticeDataDuck = meta('active_practice_data', null, {});
// export const activePracticePrivateDataDuck = meta(
//   'active_practice_private_data',
//   null,
//   {}
// );
export const activeSidebarItemDuck = meta('active_sidebar_item', 'mapper');
export const drawerOpenDuck = meta('drawer_open', false, false);
export const loadingDuck = meta('loading', {}, {});
export { mapperDuck };

const rootReducer = combineReducers({
  uid: uidDuck.REDUCER,
  userData: userDataDuck.REDUCER,
  users: usersDuck.REDUCER,
  allPerms: allPermsDuck.REDUCER,
  permissions: permissionsDuck.REDUCER,
  mapper: mapperReducer,
  admin: adminDuck.REDUCER,
  admins: adminsDuck.REDUCER,
  activePractice: activePracticeDuck.REDUCER,
  // activePracticeData: activePracticeDataDuck.REDUCER,
  // activePracticePrivateData: activePracticePrivateDataDuck.REDUCER,
  activeSidebarItem: activeSidebarItemDuck.REDUCER,
  drawerOpen: drawerOpenDuck.REDUCER,
  loading: loadingDuck.REDUCER,
});

export default rootReducer;
