import React from 'react';

import posed from 'react-pose';

export const Collapsible = posed.div({
  show: {
    opacity: 1,
    height: 'auto',
  },
  hide: {
    opacity: 0,
    height: '0px',
  },
});

class Wrap extends React.Component {
  render() {
    const { children } = this.props;
    if (Array.isArray(children)) {
      return children.map((child, i) => (
        <Wrap key={i} {...this.props}>
          {child}
        </Wrap>
      ));
    }

    return (
      <Collapsible
        pose={this.props.in ? 'show' : 'hide'}
        style={{ overflow: this.props.in ? 'visible' : 'hidden' }}
      >
        <div {...this.props}>{children}</div>
      </Collapsible>
    );
  }
}

export default Wrap;
