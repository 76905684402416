import React from 'react';
import { connect } from 'react-redux';
import { Modal, message } from 'antd';
import store from '../store';
import { activePracticeDuck, loadingDuck, drawerOpenDuck } from '../ducks/root';
import * as globals from '../globals';
import * as util from '../util';

class CreateNew extends React.Component {
  state = { show: false, name: '', website: '' };

  render() {
    return (
      <div style={this.props || {}}>
        <Modal
          title="Create new practice"
          visible={this.state.show}
          onCancel={() => {
            this.setState({ show: false });
          }}
          onOk={async () => {
            if (!this.state.name || !this.state.website) {
              message.error('Missing required field');
              return;
            }
            this.setState({ show: false });
            store.dispatch(
              loadingDuck.set({
                show: true,
                text: 'Creating practice...',
              })
            );
            try {
              let entityRef = globals.db.child('practices').push();
              let privEntityRef = globals.db.child(
                `private/practices/${entityRef.key}`
              );

              // IMPORTANT
              // must write to permissions
              // BEFORE writing any data to entityRef
              await globals.db
                .child('permissions')
                .child(this.props.uid)
                .child(entityRef.key)
                .set(true);

              console.log('set permissions!');
              let id = util.randomString(12);
              const now = new Date();
              await entityRef.set({
                name: this.state.name,
                website: this.state.website,
                id: id,
                createdAt: now.toISOString(),
              });
              console.log(`creating!! ${id}`);

              await privEntityRef.set({
                accessCode: util.randomString(20),
                id: id,
              });

              store.dispatch(activePracticeDuck.set(entityRef.key));
              store.dispatch(drawerOpenDuck.clear());
            } catch (err) {
              console.log('err');
              console.log(err);
            }
            store.dispatch(loadingDuck.clear());
          }}
        >
          <p>
            <label className="cstm">Practice name</label>
          </p>
          <input
            className="cstm"
            type="text"
            placeholder="e.g. Awesome DPC"
            onChange={evt => {
              this.setState({ name: evt.target.value });
            }}
          />
          <br />
          <p>
            <label className="cstm">Website</label>
          </p>
          <input
            className="cstm"
            type="text"
            placeholder="e.g. https://awesomedpc.com"
            onChange={evt => {
              this.setState({ website: evt.target.value });
            }}
          />
        </Modal>
        <div
          onClick={() => {
            this.setState({ show: true });
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mstp = state => ({
  uid: state.uid,
});
export default connect(mstp)(CreateNew);
