import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

// import googleMapsClient from '@google/maps';

// export const MAPS_API_KEY = 'AIzaSyC6ey4TaIdrfOqMmhEuIAy3imrxiMKNHS4';
// export const MAPS_API_KEY = "AIzaSyAJX9-SSArlDanNnZ58zgHiTtMyjl_PD2g";
// export const MAPS_API_KEY = "AIzaSyA9PxyXN5XAeu0ptorMOkXNq3LMQrph8Kk";
export const MAPS_API_KEY = `AIzaSyCOlc6QTW-G6hzrCz_YPNcIpxYDId1UMLQ`;

// export const maps = googleMapsClient.createClient({
//   key: MAPS_API_KEY,
//   Promise: Promise,
// });

// Initialize Firebase
var config = {
  apiKey: "AIzaSyBGIDw9eYxRL6wf6ZeYqWUINPEHZFb4ZI4",
  authDomain: "dpcmapper.firebaseapp.com",
  databaseURL: "https://dpcmapper.firebaseio.com",
  projectId: "dpcmapper",
  storageBucket: "dpcmapper.appspot.com",
  messagingSenderId: "181723444131"
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}
// firebase.initializeApp(config);

let isLocal =
  typeof window !== "undefined" &&
  window !== undefined &&
  window.location !== undefined &&
  window.location.href.includes("localhost");
console.log("isLocal: " + isLocal);

export { isLocal };
export { firebase };
export const db = firebase.database().ref();
// db.settings({ timestampsInSnapshots: true });
export const auth = firebase.auth();

export const mapperURL = isLocal
  ? "http://localhost:3000"
  : "https://mapper.dpcfrontier.com";
export const serverURL = isLocal
  ? "http://localhost:5000/dpcfbackend/us-central1/api/api/"
  : "https://api.dpcfrontier.com/api/";

export const adminEmail = isLocal
  ? "colinmcd94+admin@gmail.com"
  : "phil@dpcfrontier.com";

export const stripeApiKey = isLocal
  ? "pk_test_6lGR9LLuR7HTjbCBSjxvIEQu"
  : "pk_live_TdIWhZoVYJJwJg5wcy0TJs5n";

export const stripePremiumMonthlyPlanId = isLocal
  ? "plan_EG3xVHlbDmqUKB"
  : "plan_EG3yNzGD55TE3W";
