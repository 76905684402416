import React from 'react';
import { Menu, Dropdown, Icon, message } from 'antd';
import { connect } from 'react-redux';
import store from '../store';
import { activePracticeDuck, drawerOpenDuck } from '../ducks/root';

import { Row } from './Flex';
import IconCircle from './IconCircle';
import CreateNew from './CreateNew';
import JoinExisting from './JoinExisting';
import { stringifyAddress } from '../util';

class PracticeDropdown extends React.Component {
  state = {
    showDropdown: false,
  };
  MenuP = props => (
    <p
      className="textbutton"
      onClick={evt => {
        if (evt && evt.stopPropagation) evt.stopPropagation();
        store.dispatch(activePracticeDuck.set(props.pid));
        store.dispatch(drawerOpenDuck.clear());
        message.success('Switched practice.');
        this.setState({ showDropdown: false });
        return false;
      }}
      style={{ margin: '3px 0px' }}
    >
      {props.children}
    </p>
  );

  render() {
    if (!this.props.activePractice) {
      return null;
    }
    this.cache = this.cache || {};
    let MenuP = this.MenuP;
    let allKeys = Object.keys(this.props.practices);
    let sortedPracs;
    if (!this.cache[allKeys.length]) {
      // console.log('sorting');
      let allPracs = allKeys.map(pid => {
        const prac = this.props.practices[pid];
        // console.log(`${prac.name}: ${pid}`);
        return {
          ...prac,
          pid: pid,
        };
      });
      sortedPracs = allPracs.sort((x, y) => {
        return ('' + x.name).localeCompare(y.name);
      });
      this.cache[allKeys.length] = sortedPracs;
    } else {
      console.log('returning cached sorted list');
      sortedPracs = this.cache[allKeys.length];
    }
    const menu = (
      <Menu style={{ maxHeight: '400px', overflow: 'scroll' }}>
        {this.props.admin === true && (
          <Menu.Item>
            <p
              onClick={() => {
                store.dispatch(activePracticeDuck.clear());
              }}
            >
              Return to Admin Dashboard
            </p>
          </Menu.Item>
        )}
        {this.props.admin !== true &&
          sortedPracs.map((prac, i) => {
            return (
              <Menu.Item key={prac.pid}>
                <MenuP pid={prac.pid}>
                  <p style={{ lineHeight: 1, margin: 0 }}>
                    {prac.name || 'Unnamed'}
                  </p>
                  {prac.address && prac.address.line1 && (
                    <p style={{ lineHeight: 1, margin: 0 }}>
                      <small>{stringifyAddress(prac.address)}</small>
                    </p>
                  )}
                </MenuP>
              </Menu.Item>
            );
          })}
        <Menu.Divider />
        <Menu.Item>
          <JoinExisting>
            <p style={{ margin: '0px' }}>Join existing practice</p>
          </JoinExisting>
        </Menu.Item>
        <Menu.Item>
          <CreateNew>
            <p style={{ margin: '0px' }}>Create new practice</p>
          </CreateNew>
        </Menu.Item>
      </Menu>
    );

    // const MenuP = props => (
    //   <p style={{ margin: '0px 7px', fontSize: '115%' }}>{props.children}</p>
    // );

    let leaf = 'No practice selected.';
    if (
      this.props.practices &&
      this.props.activePractice &&
      typeof this.props.activePractice === 'string'
    ) {
      leaf = (
        <p style={{ margin: '0px 7px', fontSize: '115%' }}>
          {this.props.practices[this.props.activePractice]
            ? this.props.practices[this.props.activePractice].name
            : ''}
        </p>
      );
      // leaf = (
      //   <GenericLeaf
      //     path={`practices/${this.props.activePractice}/name`}
      //     component={MenuP}
      //   />
      // );
    }

    return (
      <Dropdown
        overlay={menu}
        visible={this.state.showDropdown}
        onVisibleChange={flag => {
          this.setState({ showDropdown: flag });
        }}
      >
        <div style={{ cursor: 'pointer' }}>
          <Row justify="start">
            <IconCircle icon="shop" />
            {leaf}
            <Icon type="down" />
          </Row>
        </div>
      </Dropdown>
    );
  }
}

const mstp = state => ({
  permissions: state.permissions,
  practices: (state.mapper || {}).practices,
  activePractice: state.activePractice,
  admin: state.admin,
});
export default connect(mstp)(PracticeDropdown);
