import React from 'react';
import { message, Button } from 'antd';
import { Column } from './Flex';
import Wrap from './Wrap';
import * as globals from '../globals';
// import '../style/LoginForm.scss';
import logo from '../img/blacklogo.png';

class LoginForm extends React.Component {
  state = {
    first: '',
    last: '',
    email: '',
    password: '',
    mode: 'login',
    loading: false,
  };

  set = key => evt => {
    this.setState({
      [key]: evt.target.value,
    });
  };

  submit = async evt => {
    if (evt && evt.preventDefault) evt.preventDefault();
    this.setState({ loading: true });
    try {
      console.log('Submitting form');

      if (this.state.mode === 'signup') {
        let user = await globals.auth.createUserWithEmailAndPassword(
          this.state.email,
          this.state.password
        );
        console.log(user);
        console.log(user.user);
        console.log(user.user.uid);
        globals.db.child(`users/${user.user.uid}`).set({
          first: this.state.first,
          last: this.state.last,
          email: this.state.email,
          created: Date.now(),
        });
      } else if (this.state.mode === 'login') {
        console.log('signin in user');
        await globals.auth.signInWithEmailAndPassword(
          this.state.email,
          this.state.password
        );
      } else if (this.state.mode === 'forgot') {
        await globals.auth.sendPasswordResetEmail(this.state.email);
      }
    } catch (err) {
      console.log(err);
      console.log(err.message);
      message.error(err.message);
    }
    this.setState({ loading: false });
    return false;
  };

  setMode = mode => () => {
    this.setState({ mode: mode });
  };

  componentDidMount() {
    // if (globals.isLocal) {
    //   console.log('Auto loggin in colinmcd94');
    //   this.setState({ email: 'colinmcd94@gmail.com', password: 'peanuts' });
    //   setTimeout(() => {
    //     this.submit();
    //   }, 75);
    // }
  }
  render() {
    let buttonText = {
      signup: 'Create account',
      login: 'Log in',
      forgot: 'Reset password',
    };

    return (
      <form onSubmit={this.submit}>
        <Column className={'LoginForm'}>
          <img
            alt="dpc frontier logo"
            src={logo}
            style={{ width: '100px', margin: '15px' }}
          />
          <p
            style={{
              color: 'grey',
              fontSize: '11pt',
              width: '200px',
              textAlign: 'center',
            }}
          >
            Create a free account to list your practice in the DPC Mapper
          </p>
          <div style={{ height: '40px' }} />
          <Wrap style={{ margin: '3px 0px' }} in={this.state.mode === 'signup'}>
            <input
              className="cstm"
              type="text"
              value={this.state.first}
              onChange={this.set('first')}
              placeholder={'First name'}
            />
          </Wrap>
          <Wrap style={{ margin: '3px 0px' }} in={this.state.mode === 'signup'}>
            <input
              className="cstm"
              type="text"
              value={this.state.last}
              onChange={this.set('last')}
              placeholder={'Last name'}
            />
          </Wrap>
          <Wrap style={{ margin: '3px 0px' }} in={true}>
            <input
              className="cstm"
              type="text"
              value={this.state.email}
              onChange={this.set('email')}
              placeholder={'Email'}
            />
          </Wrap>
          <Wrap style={{ margin: '3px 0px' }} in={this.state.mode !== 'forgot'}>
            <input
              className="cstm"
              type="password"
              value={this.state.password}
              onChange={this.set('password')}
              placeholder={'Password'}
            />
          </Wrap>
          <button type="submit" style={{ visibility: 'hidden' }}>
            login
          </button>
          <Button
            onClick={this.submit}
            loading={this.state.loading}
            type="primary"
          >
            {buttonText[this.state.mode]}
          </Button>
          <br />
          {this.state.mode !== 'login' && (
            <p className={'linkbutton'} onClick={this.setMode('login')}>
              Log in
            </p>
          )}
          {this.state.mode !== 'signup' && (
            <p className={'linkbutton'} onClick={this.setMode('signup')}>
              Create account
            </p>
          )}
          {this.state.mode !== 'forgot' && (
            <p className={'linkbutton'} onClick={this.setMode('forgot')}>
              Forgot password?
            </p>
          )}
        </Column>{' '}
      </form>
    );
  }
}

export default LoginForm;
