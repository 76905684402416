import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { StripeProvider } from "react-stripe-elements";
import store from "./store";
import Dashboard from "./pages/dashboard.js";

import AuthManager from "./components/AuthManager";
import MapperDataManager from "./components/MapperDataManager";
// import PracticeDataManager from './components/PracticeDataManager';
import Loading from "./components/Loading";
import * as globals from "./globals";
import * as serviceWorker from "./serviceWorker";

import "antd/dist/antd.css";
import "./style/App.scss";

if (process.browser) {
  serviceWorker.unregister();
  window.globals = globals;
}

class App extends Component {
  render() {
    return (
      <StripeProvider apiKey={globals.stripeApiKey}>
        <Provider store={store}>
          <Router>
            <div>
              <AuthManager />
              <MapperDataManager />
              <Loading />
              <Route path="/" exact component={Dashboard} />
            </div>
          </Router>
        </Provider>
      </StripeProvider>
    );
  }
}

// ReactDOM.render(<App />, document.getElementById('root'));
export default App;

// import React, { Component } from 'react';
// import logo from './logo.svg';
// import './App.css';

// class App extends Component {
//   render() {
//     return (
//       <div className="App">
//         <header className="App-header">
//           <img src={logo} className="App-logo" alt="logo" />
//           <p>
//             Edit <code>src/App.js</code> and save to reload.
//           </p>
//           <a
//             className="App-link"
//             href="https://reactjs.org"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             Learn React
//           </a>
//         </header>
//       </div>
//     );
//   }
// }

// export default App;
