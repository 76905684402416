import React from 'react';
// import * as styles from '../style';

export default props => {
  let style = { marginBottom: '7px' };
  let elProps = { style };
  return (
    <div className="card">
      <h5 {...elProps}>Share access</h5>
      <p {...elProps}>
        To share administrative access to this mapper profile with someone else,
        send them this access code:
      </p>

      <p style={{ margin: '10px 4px 12px 0px' }}>
        <b
          style={{
            border: '1px solid #00000030',
            backgroundColor: '#ffffffb0',
            borderRadius: '3px',
            fontWeight: '600',
            padding: '5px 10px',
            fontFamily: 'monospace',
          }}
        >
          {props.value}
        </b>
      </p>

      <p {...elProps}>
        The recipient should create a DPC Frontier account (if they haven't
        already), click the dropdown in the upper left corner of the Dashboard,
        then click "Join existing practice". After providing an access code, the
        user will be able to modify this practice's mapper information.
      </p>
    </div>
  );
};
