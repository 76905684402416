import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';

import UserMenu from './UserMenu';
import PracticeDropdown from './PracticeDropdown';
import Sidebar from './Sidebar';
import MobileHeader from './MobileHeader';
import DashboardContent from './DashboardContent';
import MapperSaveButton from './MapperSaveButton';
import Spacer from './Spacer';
import save from '../save';

class Dashboard extends React.Component {
  componentDidMount() {
    setInterval(() => {
      save({ auto: true });
    }, 60000);
  }
  render() {
    if (
      !this.props.permissions ||
      (this.props.admin !== true && !this.props.activePractice)
    ) {
      return (
        <div className="fullpage">
          <Spin />
        </div>
      );
    }

    const showAdmin = this.props.admin === true && !this.props.activePractice;
    const saveBtn = !showAdmin ? (
      <div className="saveButtonContainer">
        <MapperSaveButton />
      </div>
    ) : null;

    return (
      <div className={'Dashboard'}>
        <div className="desktop hidesmall hidemedium">
          <div className="dashheader">
            <PracticeDropdown />
            <Spacer flex />
            <UserMenu />
          </div>
          <div className="body">
            <Sidebar id="#desktopdash" />
            <DashboardContent id="#desktopdash" />
            {saveBtn}
          </div>
        </div>

        <div className="mobile showsmall showmedium">
          <MobileHeader />
          <div className="body">
            <DashboardContent id="#mobiledash" />
            {saveBtn}
          </div>
        </div>
      </div>
    );
  }
}

const mstp = state => ({
  permissions: state.permissions,
  activePractice: state.activePractice,
  admin: state.admin,
});
export default connect(mstp)(Dashboard);
