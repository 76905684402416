import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Select, message, Spin } from 'antd';

import { countries } from '../data';
import Spacer from './Spacer';
import { Row } from './Flex';
import * as geo from '../geo';
import * as util from '../util';
import save from '../save';
const Option = Select.Option;

class FireAddress extends React.Component {
  state = { open: false, receivedAddress: false, loading: false };

  componentDidMount() {
    // if (this.props.address === undefined) return;
    // this.setState({
    //   ...this.props.address,
    //   country: this.props.address.country || 'US',
    // });
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (
  //     this.props.address !== nextProps.address &&
  //     this.state.receivedAddress === false
  //   ) {
  //     this.setState({ ...nextProps.address, receivedAddress: true });
  //   }
  //   return true;
  // }

  inputFactory = leaf => {
    let value;
    if (this.state[leaf] !== undefined) {
      value = this.state[leaf];
    } else if (!this.props.address) {
      value = '';
    } else {
      value = this.props.address[leaf];
    }
    return (
      <input
        type="text"
        style={{ marginBottom: '10px' }}
        className="cstm"
        value={value}
        onChange={evt => {
          this.setState({ [leaf]: evt.target.value });
        }}
      />
    );
  };

  render() {
    const address = this.props.address || {};
    let { line1, line2, city, region, postal, country } = this.state;
    line1 = line1 === undefined ? address.line1 : line1;
    line1 = line1 === undefined ? address.line1 : line1;
    city = city === undefined ? address.city : city;
    region = region === undefined ? address.region : region;
    postal = postal === undefined ? address.postal : postal;
    country = country === undefined ? address.country : country;

    let addrExists = address !== undefined && Object.keys(address).length !== 0;
    console.log('addr props');
    console.log(JSON.stringify(this.props.address, null, 2));
    console.log('addr state');
    console.log(JSON.stringify(this.state, null, 2));

    return (
      <div>
        {addrExists && (
          <div>
            <p>{address.line1}</p>
            <p>{address.line2}</p>
            <p>{`${address.city}, ${address.region} ${address.postal}`}</p>
          </div>
        )}

        <Button
          type="primary"
          onClick={() => {
            this.setState({ open: true });
          }}
        >
          {addrExists ? 'Update address' : 'Set address'}
        </Button>
        <br />
        <br />

        <Modal
          title="Update address"
          visible={this.state.open}
          footer={
            <Row justify="flex-end">
              <Button
                onClick={() => {
                  this.setState({ open: false });
                }}
              >
                Cancel
              </Button>
              <Spacer size="small" />
              <Button
                type="primary"
                onClick={async () => {
                  if (!line1 || !city || !region || !postal) {
                    message.error('Missing required fields.');
                    return;
                  }
                  this.setState({ loading: true });
                  try {
                    let fullAddr = {
                      line1: line1,
                      line2: line2 || '',
                      city: city,
                      region: region,
                      postal: postal,
                      country: country || 'US',
                    };

                    // console.log(JSON.stringify(fullAddr, null, 2));
                    let addrString = util.addrString(fullAddr);
                    console.log(addrString);
                    let latlng = await geo.latlng(addrString);

                    if (latlng) {
                      console.log('got latlng');
                      console.log(JSON.stringify(latlng, null, 2));
                      this.props.writeLeaf('address')(fullAddr);
                      this.props.writeLeaf('lat')(latlng.lat);
                      this.props.writeLeaf('lng')(latlng.lng);
                    } else {
                      console.log('Error geocoding');
                      throw new Error(
                        'Error trying to parse your address. Make sure there are no typos.'
                      );
                    }
                  } catch (err) {
                    console.log('Error parsing address!');
                    console.log(err);
                    message.error(err.message);
                  }
                  this.setState({ loading: false });
                  this.setState({ open: false, loading: false });
                  setTimeout(save, 500);
                }}
              >
                {this.state.loading ? <Spin size="small" /> : 'Save'}
              </Button>
            </Row>
          }
        >
          <p>
            <small>Line 1 *</small>
          </p>
          {this.inputFactory('line1')}
          <p>
            <small>Line 2</small>
          </p>
          {this.inputFactory('line2')}
          <p>
            <small>City *</small>
          </p>
          {this.inputFactory('city')}
          <p>
            <small>State/region *</small>
          </p>
          {this.inputFactory('region')}
          <p>
            <small>Zip/postal code *</small>
          </p>
          {this.inputFactory('postal')}
          <p>
            <small>Country *</small>
          </p>
          <Select
            defaultValue="US"
            onChange={value => {
              this.setState({ country: value });
            }}
            value={
              this.state.country !== undefined
                ? this.state.country
                : address.country || 'US'
            }
          >
            {Object.keys(countries).map(label => {
              let value = countries[label];
              return (
                <Option key={value} value={value}>
                  {label}
                </Option>
              );
            })}
          </Select>
        </Modal>
      </div>
    );
  }
}

const mstp = state => ({});
export default connect(mstp)(FireAddress);
