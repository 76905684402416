import React from 'react';
import { Button, message } from 'antd';
import { Column } from './Flex';
import * as globals from '../globals';
// import '../style/SetPassword.scss';

class SetPassword extends React.Component {
  state = {
    //   first: '',
    //   last: '',
    //   email: 'colinmcd94@gmail.com',
    password: '',
    loading: false,
    //   mode: 'login',
  };

  set = key => evt => {
    this.setState({
      [key]: evt.target.value,
    });
  };

  submit = async evt => {
    evt.stopPropagation();
    this.setState({ loading: true });
    try {
      console.log('Setting new password');
      let user = globals.auth.currentUser;
      console.log('updated password');
      await globals.db.child(`users/${user.uid}/passwordSet`).set(true);
      console.log('updating pass: ' + this.state.password);
      await user.updatePassword(this.state.password);
      console.log('set passwordSet to true');
    } catch (err) {
      console.log('ERR changing pass');
      console.log(err);
      console.log(err.message);
      if (globals.auth.currentUser) {
        await globals.db
          .child(`users/${globals.auth.currentUser.uid}/passwordSet`)
          .set(false);
      }

      if (err.message.includes('recent')) {
        message.error(err.message);
        setTimeout(() => {
          globals.auth.signOut();
        }, 3000);

        return;
      } else {
        message.error(err.message);
      }
    }
    this.setState({ loading: false });
  };

  render() {
    return (
      <form onSubmit={this.submit}>
        <Column className={'SetPassword'}>
          <small>Set a permanent password</small>
          <input
            className="cstm"
            type="password"
            value={this.state.password}
            onChange={this.set('password')}
            placeholder={'New password'}
          />

          <Button
            style={{ margin: '3px 0px' }}
            type="primary"
            loading={this.state.loading}
            onClick={this.submit}
          >
            Update
          </Button>
        </Column>
      </form>
    );
  }
}

export default SetPassword;
