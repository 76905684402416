import React from 'react';
import { Button, message } from 'antd';
import { Column } from './Flex';
import * as globals from '../globals';
// import '../style/WaitForVerification.scss';

class WaitForVerification extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.resend();
  }

  resend = async () => {
    this.setState({ loading: true });
    try {
      console.log('sending email verificatoin');
      let user = globals.auth.currentUser;
      await user.sendEmailVerification();
    } catch (err) {
      console.log(err);
      message.error(err.message);
    }
    this.setState({ loading: false });
  };

  render() {
    let user = globals.auth.currentUser;
    return (
      <Column className={'WaitForVerification'}>
        <div style={{ maxWidth: '400px' }}>
          <p style={{ textAlign: 'center' }}>
            {`A verification email has been sent to ${user.email}.`}
          </p>
          <br />
          <p style={{ textAlign: 'center' }}>
            Click the link in that email, then return to this page and press
            Continue below.
          </p>
        </div>
        <Button
          type="primary"
          onClick={() => {
            window.location = window.location.href.split('#')[0];
          }}
        >
          Continue
        </Button>
        <br />
        <small>Don't see the email?</small>
        <Button
          style={{ margin: '3px 0px' }}
          size="small"
          loading={this.state.loading}
          onClick={this.resend}
        >
          Resend
        </Button>
      </Column>
    );
  }
}

export default WaitForVerification;
