import React from 'react';

import { connect } from 'react-redux';
import moment from 'moment';
import { Input, Radio, Checkbox, Icon, Button, DatePicker } from 'antd';

import * as _ from 'lodash';

import * as util from '../util';
import { Row } from './Flex';
import Spacer from './Spacer';
import FireUpload from './FireUpload';
import FireAddress from './FireAddress';
import UniversalComponent from './UniversalComponent';

import store from '../store';
import { mapperDuck } from '../ducks/root';

const { TextArea } = Input;
const RadioGroup = Radio.Group;

class BaseFireInput extends React.PureComponent {
  writeLeaf = path => value => {
    let fullPath;
    if (Array.isArray(path)) {
      fullPath = [...this.props.branch, ...path];
    } else if (typeof path === 'string' || typeof path === 'number') {
      fullPath = [...this.props.branch, path];
    } else {
      console.log(typeof path);
      console.log(path);
      console.log(value);
      throw new Error('Invalid type for parameter `path` passed to writeLead.');
    }

    store.dispatch(mapperDuck.update(fullPath, value));
    // globals.db.child(fullPath.join('/')).set(value);
  };

  setValue = value => {
    this.writeLeaf(this.props.schema.leaf)(value);
  };

  onChange = evt => {
    let val = evt.target.value;
    this.setValue(val);
  };

  render() {
    const { branch, schema, value } = this.props;

    if (schema.admin === true && this.props.admin !== true) return null;
    let name = branch.join('__');

    let path = [...branch, schema.leaf];
    let type = schema.type || 'text';
    let style = {};

    let element = null;
    let beforeSpacer = 'none';
    let afterSpacer = 'none';
    let label = schema.label ? (
      <label className="cstm" htmlFor={name}>
        {schema.label}
      </label>
    ) : null;

    let elementProps = {
      // key: 'input',
      name: name,
      // style: { marginBottom: '4px' },
      value: util.exists(value) ? value : '',
    };

    const locked =
      schema.minPlan !== undefined && this.props.plan !== schema.minPlan;

    switch (type) {
      case 'text':
      case 'number':
        element = (
          <Input
            {...elementProps}
            type={type}
            disabled={locked}
            placeholder={schema.placeholder || schema.label}
            onChange={evt => {
              let v = evt.target.value;
              if (type === 'text') {
                let cap = schema.maxChars || Infinity;
                v = (evt.target.value || '').slice(0, cap);
              } else if (type === 'number') {
                if (v !== undefined && v !== '') {
                  v = v.replace(/[^\d.]/g, '');
                  v = parseFloat(v);
                }
              }
              this.setValue(v);
            }}
          />
        );

        break;
      case 'textarea':
        element = (
          <TextArea
            {...elementProps}
            autosize // className="cstm"
            placeholder={schema.placeholder || schema.label}
            onChange={evt => {
              let cap = schema.maxChars || Infinity;
              this.setValue((evt.target.value || '').slice(0, cap));
            }}
          />
        );
        break;
      case 'date':
        // let now = new Date();
        // let mm = now.getMonth() + 1;
        // let dd = now.getDate();
        // let nowString = `${now.getFullYear()}-${mm < 10 ? '0' + mm : mm}-${
        //   dd < 10 ? '0' + dd : dd
        // }`;
        let dateVal = moment(value);
        element = (
          <DatePicker
            value={value ? dateVal : undefined}
            placeholder={schema.placeholder}
            onChange={(date, dateString) => {
              console.log(date);
              console.log(dateString);
              this.setValue(dateString);
            }}
          />
        );

        break;
      case 'radio':
        const radioStyle = {};
        element = (
          <RadioGroup {...elementProps} onChange={this.onChange}>
            {schema.options.map(({ label, value, details }, i) => {
              return (
                <div key={i}>
                  <Radio style={radioStyle} value={value}>
                    {label}
                  </Radio>
                  {details && (
                    <p
                      style={{
                        margin: '0px 0px 0px 25px ',
                        fontSize: '80%',
                      }}
                    >
                      {details}
                    </p>
                  )}
                  <Spacer size="xsmall" />
                </div>
              );
            })}
          </RadioGroup>
        );
        break;

      case 'checkbox':
        if (schema.options) {
          element = schema.options.map(({ value, label }, i) => {
            return (
              <FireInput
                key={i}
                branch={path}
                schema={{ leaf: value, label: label, type: 'checkbox' }}
              />
            );
          });
        } else if (schema.label) {
          label = null;
          element = (
            <Checkbox
              {...elementProps}
              onChange={evt => {
                this.setValue(evt.target.checked);
              }}
              checked={value || false}
            >
              {schema.label}
              {schema.details && (
                <p style={{ margin: '0px 0px 0px 25px ', fontSize: '80%' }}>
                  {schema.details}
                </p>
              )}
            </Checkbox>
          );
        }

        break;
      case 'header':
        label = (
          <a
            id={util.slugify(schema.text)}
            href={'#' + util.slugify(schema.text)}
            style={{ height: '0px', display: 'block', visibility: 'hidden' }}
          >
            {schema.text}
          </a>
        );
        element = <h3>{schema.text}</h3>;
        beforeSpacer = 'large';
        break;
      case 'subheader':
        element = <h5>{schema.text}</h5>;
        beforeSpacer = 'small';
        break;
      case 'custom':
        let Component = schema.component;
        element = <Component value={value} />;
        break;
      case 'universal':
        element = <UniversalComponent component={schema.component} />;
        break;
      case 'content':
        label = null;
        element = (
          <div className={'alert'}>
            {schema.content.map((bit, i) => {
              console.log(bit);
              return (
                <p key={i} className={bit.className || ''}>
                  {bit.text.replace(/__value__/g, value)}
                </p>
              );
            })}
          </div>
        );
        break;

      case 'image':
      case 'file':
        element = (
          <FireUpload type={type} value={value} setValue={this.setValue} />
        );
        break;

      case 'address':
        element = <FireAddress address={value} writeLeaf={this.writeLeaf} />;
        break;
      case 'array':
        // label = <h5>{schema.label}</h5>;
        // style = { paddingLeft: '10px', borderLeft: '1px solid #00000030' };
        element = (value || []).map((el, i) => {
          let fi = (
            <FireInput
              key={'fi'}
              branch={path}
              schema={{ ...schema.field, leaf: i }}
            />
          );
          let db = (
            <div className="row" style={{ marginBottom: '10px' }}>
              <Button
                key="btn"
                size="small"
                type="danger"
                icon="delete"
                onClick={() => {
                  const arrayWithoutElement = [
                    ...value.slice(0, i),
                    ...value.slice(i + 1),
                  ];
                  // console.log(JSON.stringify(arrayWithoutElement, null, 2));
                  this.setValue(arrayWithoutElement);
                }}
              >
                {schema.removeText || 'Delete'}
              </Button>
            </div>
          );
          if (schema.field.type === 'text') {
            return (
              <div className="row" key={i}>
                {fi}
                {db}
              </div>
            );
          }
          return (
            <div
              className="col"
              key={i}
              style={{
                padding: '10px',
                margin: '4px 0px',
                boxShadow: '0px 1px 4px #00000035',
                borderRadius: '7px',
              }}
            >
              {fi}
              {db}
            </div>
          );
        });
        afterSpacer = 'small';
        break;
      case 'object':
        element = (
          <Row justify="flex-start" wrap>
            {schema.fields.map((field, j) => {
              return <FireInput key={j} branch={path} schema={field} />;
            })}
          </Row>
        );
        beforeSpacer = 'small';
        break;
      default:
        break;
    }

    let styles = {
      position: 'relative',
      ...(schema.style || {}),
      ...style,
    };

    // let privData =
    //   _.get(this.props.privPractices, [this.props.activePractice]) || {};

    return (
      <div className="FireInput" style={styles} key={schema.leaf}>
        {locked && (
          <div
            className="row LockCover"
            onClick={evt => {
              evt.stopPropagation();
              return false;
            }}
          >
            <Icon type="lock" /> This feature requires a Pro plan.
          </div>
        )}
        <Spacer size={beforeSpacer} />
        <div style={{ width: '100%' }}>{label}</div>
        {element}
        {type === 'array' && (
          <div style={{ marginTop: '14px' }}>
            <Button
              icon="plus"
              size="small"
              type="primary"
              onClick={() => {
                let currVal = value || [];
                this.setValue([...currVal, '']);
              }}
            >
              {schema.addText || 'Add'}
            </Button>
          </div>
        )}
        <Spacer size={afterSpacer} />
      </div>
    );
  }
}

// const mapperSelector = state => state.mapper;
const valueSelector = (state, ownProps) => {
  const { branch, schema } = ownProps;
  const path = [...branch, schema.leaf];
  const value = _.get(state, ['mapper', ...path]);
  // const value = _.get(state, ['activePracticeData', ...path]);
  return value;
};

const mstp = (state, ownProps) => {
  // const { branch, schema } = ownProps;
  // const path = [...branch, schema.leaf];
  // const value = _.get(state, ['mapper', ...path]);
  // if (!state.activePracticeData) {
  //   return {};
  // }
  // return {
  //   value: valueSelector(state, ownProps),
  //   plan: state.activePracticePrivateData.plan,
  // };
  const privPractices = _.get(state, ['mapper', 'private', 'practices']);
  let plan = '';
  if (privPractices && state.activePractice) {
    if (privPractices[state.activePractice]) {
      plan = privPractices[state.activePractice].plan;
    }
  }

  return {
    value: valueSelector(state, ownProps),
    plan: plan,
    admin: state.admin,
  };
  // activePractice: state.activePractice,
};

const FireInput = connect(mstp)(BaseFireInput);
export default FireInput;
