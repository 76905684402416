import React from "react";

/*

  type: defaults to 'text'
  leaf: used to fetch/write to Firebase
  label: text that appears above input
  placeholder: input placeholder, defaults to label
  detail: appears as info icon with tip

*/

import MapperShareInstructions from "./components/MapperShareInstructions";
import MapperInstructions from "./components/MapperInstructions";
import Verified from "./components/Verified";
// import PlanSection from './components/PlanSection';
import { specialties } from "./data.js";

const H1 = ({ value }) => <h1>{value}</h1>;

const schema = [
  {
    type: "custom",
    leaf: "name",
    component: H1
  },
  {
    leaf: "verified",
    label: "Verified?",
    admin: true,
    type: "radio",
    options: [
      { value: true, label: "Verified" },
      { value: "rejected", label: "Rejected" },
      { value: "pending", label: "Pending verification" },
      { value: false, label: "Not yet submitted for verification" }
    ]
  },
  {
    leaf: "practiceStatus",
    label: "Practice status",
    admin: true,
    type: "radio",
    options: [
      { value: "", label: "Open" },
      // { value: 'coming', label: 'Coming soon' },
      { value: "closed", label: "Closed permanently" }
    ]
  },

  {
    type: "universal",
    component: Verified
  },
  {
    type: "custom",
    leaf: "id",
    component: MapperInstructions
  },
  // {
  //   type: 'custom',
  //   leaf: 'id',
  //   component: MapperProfileButton,
  // },
  // {
  //   type: 'universal',
  //   component: PlanSection,
  // },
  // {
  //   type: 'custom',
  //   private: true,
  //   leaf: 'subId',
  //   component: PlanSection,
  // },
  {
    type: "custom",
    private: true,
    leaf: "accessCode",
    component: MapperShareInstructions
  },
  {
    leaf: "name",
    label: "Practice name",
    placeholder: "e.g. Awesome DPC"
  },
  {
    leaf: "legalName",
    label: "Legal name",
    placeholder: "e.g. Awesome Direct Primary Care, PLLC"
  },
  // {
  //   leaf: 'tagline',
  //   label: 'Tagline or slogan',
  //   placeholder: 'e.g. Like Having a Doctor in the Family',
  // },
  {
    leaf: "website",
    label: "Website",
    placeholder: "e.g. https://awesomedpc.com"
  },
  {
    leaf: "logo",
    type: "image",
    label: "Logo (JPG or PNG)"
  },
  {
    leaf: "videoEmbed",
    label: "Video embed code"
    // minPlan: 'premium',
  },
  // {
  //   leaf: 'specialty',
  //   label: 'Specialty',
  //   type: 'radio',
  //   options: specialties,
  // },
  {
    leaf: "kind",
    label: "Practice type",
    type: "radio",
    options: [
      {
        label: "Pure",
        details: "you accept no insurance of any form ever)",
        value: "pure"
      },
      {
        label: "Hybrid",
        details: "you have some non-DPC patients",
        value: "hybrid"
      },
      {
        label: "Uncategorized",
        details: "the type of this practice is unknown",
        value: "unknown"
      }
    ]
  },
  {
    leaf: "onsite",
    label: "On-site clinic?",
    details: "you primarily serve the employees of a single employer",
    type: "checkbox"
  },
  {
    leaf: "mobile",
    label: "Mobile clinic?",
    details:
      "you have no fixed address or exclusively do house calls/workplace visits",
    type: "checkbox"
  },
  // {
  //   leaf: 'panelStatus',
  //   label: 'Patient panel status',
  //   type: 'radio',
  //   options: [
  //     { value: 'open', label: 'Open (accepting new patients)' },
  //     { value: 'waitlist', label: 'Waitlist' },
  //     { value: 'full', label: 'Full (not accepting new patients)' },
  //   ],
  // },
  { type: "header", text: "Contact info" },
  { leaf: "phone", label: "Office phone number" },
  // { leaf: 'cell', label: 'Cell phone number' },
  { leaf: "fax", label: "Fax number" },
  {
    leaf: "emails",
    private: true,
    label: "Contact email(s)",
    addText: "Add additional email",
    type: "array",
    // minPlan: 'premium',
    field: {
      type: "text",
      placeholder: "Email address"
    }
  },
  { type: "header", text: "Social media" },
  {
    leaf: "facebook",
    label: "Facebook Page URL",
    placeholder: "e.g. https://facebook.com/AwesomeDPC"
  },
  {
    leaf: "twitter",
    label: "Twitter handle",
    placeholder: "@awesomedpc"
  },
  {
    leaf: "instagram",
    label: "Instagram handle",
    placeholder: "@awesomedpc"
  },
  {
    leaf: "linkedin",
    label: "LinkedIn URL",
    placeholder: "e.g. https://linkedin.com/AwesomeDPC"
  },
  {
    leaf: "pinterest",
    label: "Pinterest URL",
    placeholder: "e.g. https://pinterest.com/AwesomeDPC"
  },
  { type: "header", text: "Practice information" },
  {
    leaf: "address",
    type: "address",
    label: "Address"
  },
  {
    type: "textarea",
    leaf: "description",
    label: "Short description (500 character max)",
    maxChars: 500
  },
  {
    leaf: "openDate",
    type: "date",
    label: "Date opened/transitioned",
    placeholder: "YYYY-MM-DD"
  },
  { leaf: "acceptedAges", label: "Accepted ages", placeholder: "e.g. 18-65" },
  { type: "subheader", text: "Location photos" },
  // { leaf: 'banner', label: 'Banner image', type: 'image' },
  {
    leaf: "locationPhotos",
    // minPlan: 'premium',
    // label: 'Location photos',
    addText: "Add additional photo",
    type: "array",
    field: {
      type: "object",
      fields: [
        { leaf: "photo", label: "Photo", type: "image" },
        { leaf: "caption", label: "Caption", style: { flexBasis: "100%" } }
      ]
    }
  },
  { type: "header", text: "Hours" },
  {
    leaf: "hours",
    // label: 'Hours',
    addText: "Add additional hours",
    type: "array",
    field: {
      type: "object",
      fields: [
        { leaf: "days", label: "Day(s) of week", placeholder: "e.g. Mon-Fri" },
        { leaf: "hours", label: "Hours open", placeholder: "e.g. 9am-5pm" }
      ]
    }
  },
  { type: "header", text: "Membership pricing" },

  {
    leaf: "prices",
    label: "Pricing tiers",
    addText: "Add pricing tier",
    type: "array",
    field: {
      type: "object",

      fields: [
        { leaf: "fee", label: "Tier description" },
        {
          leaf: "amount",
          label: "Price (dollars)",
          type: "number",
          placeholder: "e.g. 75"
        }
      ]
    }
  },
  {
    leaf: "pricingDetails",
    type: "textarea",
    label: "Additional membership pricing details"
  },

  { type: "header", text: "Cash-pay services" },
  {
    leaf: "procedureFees",
    type: "array",
    addText: "Add service",
    field: {
      type: "object",
      fields: [
        { leaf: "fee", label: "Service" },
        {
          leaf: "amount",
          label: "Price (dollars)",
          type: "number",
          placeholder: "e.g. 75"
        }
      ]
    }
  },

  { type: "header", text: "Extra fees" },
  {
    leaf: "enrollFee",
    label: "Enrollment fee (dollars)",
    type: "number",
    placeholder: "e.g. 100"
  },
  {
    leaf: "visitFee",
    label: "Per-visit fee for MEMBERS (dollars)",
    type: "number",
    placeholder: "e.g. 15"
  },
  {
    leaf: "nonMemberVisitFee",
    label: "Per-visit fee for NON-MEMBERS (dollars)",
    type: "number",
    placeholder: "e.g. 15"
  },
  {
    leaf: "additionalFees",
    label: "Miscellaneous fees",
    addText: "Add miscellaneous fee",
    type: "array",
    field: {
      type: "object",
      fields: [
        { leaf: "fee", label: "Fee/reason" },
        { leaf: "amount", label: "Amount", type: "number" }
      ]
    }
  },
  // {
  //   leaf: 'feeCategories',
  //   // label: 'Pricing tiers',
  //   addText: 'Add fee list',
  //   type: 'array',
  //   field: {
  //     type: 'object',

  //     fields: [
  //       { leaf: 'name', label: 'Fee list name' },
  //       {
  //         leaf: 'fees',
  //         type: 'array',
  //         addText: 'Add fee',
  //         field: {
  //           type: 'object',
  //           fields: [
  //             { leaf: 'fee', label: 'Fee name' },
  //             {
  //               leaf: 'amount',
  //               label: 'Amount (dollars)',
  //               type: 'number',
  //               placeholder: 'e.g. 75',
  //             },
  //           ],
  //         },
  //       },
  //       { leaf: 'details', type: 'textarea', label: 'Additional details' },
  //     ],
  //   },
  // },

  { type: "header", text: "Staff bios" },
  {
    leaf: "people",
    addText: "Add additional staff member",
    type: "array",
    field: {
      type: "object",
      fields: [
        { leaf: "fn", label: "First name" },
        { leaf: "ln", label: "Last name" },
        { leaf: "cert", label: "Degree", placeholder: "e.g. MD" },

        { leaf: "image", label: "Profile image", type: "image" },
        {
          leaf: "bio",
          label: "Brief bio (300 character max)",
          type: "textarea",
          maxChars: 300
        },
        {
          leaf: "prof",
          label: "Online profile",
          placeholder: "e.g. https://doximity.com/pub/doogie-howser-md"
        },
        {
          leaf: "specialty",
          label: "Specialty",
          type: "radio",
          options: specialties
        },
        {
          leaf: "panelStatus",
          label: "Patient panel status",
          type: "radio",
          options: [
            { value: "open", label: "Open (accepting new patients)" },
            { value: "waitlist", label: "Waitlist" },
            { value: "full", label: "Full (not accepting new patients)" }
          ]
        }
      ]
    }
  },
  { type: "header", text: "Cost savings" },
  { leaf: "dispensing", label: "In-office dispensing?", type: "checkbox" },
  { leaf: "labDiscounts", label: "Cash pricing on labs?", type: "checkbox" },
  {
    leaf: "radiologyDiscounts",
    label: "Cash pricing on radiology?",
    type: "checkbox"
  },
  { type: "header", text: "Patient communication" },
  { leaf: "messageCommunication", label: "Texting/SMS?", type: "checkbox" },
  { leaf: "emailCommunication", label: "Email", type: "checkbox" },
  { leaf: "cellCommunication", label: "Cell phone calls", type: "checkbox" },
  { leaf: "videoCommunication", label: "Telemed/video chat", type: "checkbox" },
  { leaf: "homeVisits", label: "Home/worksite visits?", type: "checkbox" },
  // { type: 'header', text: 'Documents' },
  // {
  //   leaf: 'documents',
  //   // label: 'Documents',
  //   type: 'array',
  //   addText: 'Add document',
  //   field: {
  //     type: 'object',
  //     fields: [
  //       { leaf: 'name', label: 'Document title' },
  //       { leaf: 'file', type: 'file', label: 'Upload file' },
  //     ],
  //   },
  // },
  // { leaf: 'file', type: 'file', label: 'Random document (PDF only)' },
  { type: "header", text: "Patient enrollment" },
  {
    leaf: "enrollment_url",
    label: "Enrollment form URL",
    placeholder: "e.g. https://website.com/signup"
    // minPlan: 'premium',
  },
  {
    leaf: "enrollment_code",
    label: "Embedded enrollment form code snippet",
    placeholder: 'e.g. <iframe src="URL HERE" />'
    // minPlan: 'premium',
  }
  // {
  //   leaf: 'scheduling_url',
  //   label: 'Scheduling page URL',
  //   placeholder: 'e.g. https://calendly.com/awesomedpc',
  // },
  // {
  //   leaf: 'scheduling_code',
  //   label: 'Embedded scheduling widget',
  //   placeholder: 'Should start with "<iframe"',
  // },
];
export default schema;
