import React from 'react';
import MapperProfileButton from './MapperProfileButton';
export default function MapperInstructions(props) {
  let style = { marginBottom: '12px' };
  let elProps = { style };
  return (
    <div className="card">
      <h5 {...elProps}>Instructions</h5>
      <ol style={{ listStyle: 'inherit', marginLeft: '25px' }}>
        <li {...elProps}>
          {' '}
          Click the button to open your practice's mapper profile:
          <br /> <MapperProfileButton value={props.value} />
        </li>
        <li {...elProps}>
          Add additional information about your practice in the fields below.
          Use the sidebar to jump to a particular section.
        </li>
        <li {...elProps}>
          Hit the Save button at the bottom middle of the page to save your
          progress!
        </li>
      </ol>
    </div>
  );
}
