import { message } from 'antd';

import * as globals from './globals';
import store from './store';

export default async (params = {}) => {
  const auto = params.auto || false;
  const { practices, private: privatePractices } = store.getState().mapper;
  const admin = store.getState().admin;
  if (admin === true && auto) {
    console.log('ADMIN!! not saving....');
    return;
  }

  // console.log(JSON.stringify(practices, null, 2));
  // console.log(JSON.stringify(privatePractices, null, 2));
  let p1 = globals.db.child('practices').update(practices);
  let p2 = globals.db
    .child('private/practices')
    .update(privatePractices.practices);
  let proms = [p1, p2];
  await Promise.all(proms);
  message.success(auto ? 'Auto-saved.' : 'Saved.');
};
