import * as globals from "./globals";

export const serialize = obj => {
  var str = [];
  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(
        encodeURIComponent(p) + "=" + encodeURIComponent(JSON.stringify(obj[p]))
      );
    }
  }
  return str.join("&");
};

export const randomString = (len = 10) => {
  var text = "";
  var possible = "abcdefghijklmnopqrstuvwxyz";

  for (var i = 0; i < len; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

export const slugify = str => {
  return str.toLowerCase().replace(/[^a-z]+/g, "_");
};

export const upload = async (file, path, callback) => {
  console.log(file);
  console.log("starting upload...");
  var ref = globals.firebase
    .storage()
    .ref()
    .child(path);
  console.log(ref);
  let uploadTask = ref.put(file);

  uploadTask.on(
    globals.firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
    () => {},
    error => {
      console.log(error);
      callback(error);
      switch (error.code) {
        case "storage/unauthorized":
          break;
        case "storage/canceled":
          break;
        case "storage/unknown":
          break;
        default:
          break;
      }
    },
    () => {
      // Upload completed successfully, now we can get the download URL
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        console.log("File available at", downloadURL);
        callback(null, downloadURL);
      });
    }
  );
};

export const stringifyAddress = addr => {
  const addrComps = [
    addr.line1,
    addr.line2,
    addr.city,
    `${addr.region}${addr.postal ? " " + addr.postal : ""}`
  ];
  addrComps.filter(x => x).join(", ");
};
export const addrString = addr => {
  let addrComps = [
    addr.line1,
    addr.line2,
    addr.city,
    `${addr.region}${addr.postal ? " " + addr.postal : ""}`
  ];
  return encodeURIComponent(addrComps.filter(x => x).join(", "));
};

export const addrLink = addr => {
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    addrString(addr)
  )}`;
};

export const inout = obj => {
  let newobj = {};
  for (let key in obj) {
    let val = obj[key];
    newobj[val] = key;
  }
  return newobj;
};

export const objify = (arr, keyKey = "value", valueKey = "label") => {
  let obj = {};
  for (let item of arr) {
    obj[item[keyKey]] = item[valueKey];
  }
  return obj;
};

export const exists = val => {
  if (val === undefined) return false;
  if (typeof val === "number" && isNaN(val)) return false;
  if (val === null) return false;
  if (Array.isArray(val) && val.length === 0) return false;
  if (typeof val === "object" && Object.keys(val).length === 0) return false;
  if (typeof val === "string" && val.length === 0) return false;

  return true;
};
