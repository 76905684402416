const globals = require('./globals');

export const latlng = async address => {
  let resp = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${
      globals.MAPS_API_KEY
    }`
  );
  let data = await resp.json();

  if (data.results && data.results[0]) {
    let loc = data.results[0];
    if (loc.geometry && loc.geometry.location) {
      return {
        lat: loc.geometry.location.lat,
        lng: loc.geometry.location.lng,
      };
    }
  }
  return null;
};
