import React from 'react';
import { Button, message } from 'antd';

import save from '../save';
import * as API from '../api';
import * as globals from '../globals';

class Verified extends React.PureComponent {
  state = { loading: false };
  render() {
    const { data, activePractice } = this.props;
    let content;

    const elProps = { style: { marginBottom: '7px' } };
    if (data.verified === true) {
      content = <p>This practice has been verified.</p>;
    } else if (data.verified === 'pending') {
      content = <p>This practice has been submitted for verification.</p>;
    } else if (data.verified === 'rejected') {
      content = (
        <p>
          This practice has been rejected. You likely received an email
          explaining why this occurred. If not, reach out to
          phil@dpcfrontier.com for details.
        </p>
      );
    } else {
      content = (
        <div>
          <h5 {...elProps}>Verification</h5>
          <p {...elProps}>
            This practice has not been verified. You will not appear in the
            mapper before you've been verified by DPC Frontier. Fill out as much
            information as possible, then request verification by clicking the
            button below.
          </p>
          <div>
            <Button
              loading={this.state.loading}
              onClick={async () => {
                // Email phil is this is the first save
                if (!data.name || !data.website) {
                  message.error(
                    'You must provide a practice name and website URL.'
                  );
                  return;
                }
                this.setState({ loading: true });
                try {
                  console.log('sending verification email');
                  await API.post(['send'], {
                    to: globals.adminEmail,
                    from: 'noreply@dpcfrontier.com',
                    subject: '[DPC Frontier] New practice added to mapper',
                    html: [
                      `A new practice has been added to the mapper.`,
                      `Name: ${data.name}<br/>
                      Website: <a href="${data.website}">${
                        data.website
                      }</a><br />
                      User: ${globals.auth.currentUser.email}
                      `,
                      `Click to verify: <a href="${
                        globals.serverURL
                      }verifyPractice/${activePractice}">Verify practice</a><br/>Click to reject: <a href="${
                        globals.serverURL
                      }rejectPractice/${activePractice}">Reject practice</a>.`,
                      `Additional information: <br/>${JSON.stringify(
                        data,
                        null,
                        2
                      )
                        .replace(/[ ]/g, '&nbsp;&nbsp;')
                        .replace(/\n/g, '<br />')}`,
                    ].join('<br /><br />'),
                  });

                  // save practice
                  save();

                  console.log('setting verified to pending');
                  console.log('activeP: ' + activePractice);
                  await globals.db
                    .child(`practices/${activePractice}/verified`)
                    .set('pending');
                } catch (err) {
                  console.log(err);
                }
                this.setState({ loading: false });
              }}
            >
              Submit for verification
            </Button>
          </div>
        </div>
      );
    }

    return <div className="card">{content}</div>;
  }
}

export default Verified;
