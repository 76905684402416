import React from 'react';
import { Upload, Icon, Button, Modal, Spin, message } from 'antd';
import { connect } from 'react-redux';

import { Row } from './Flex';
import * as util from '../util';

class FireUpload extends React.Component {
  state = { loading: false };

  uploadFile = fileTypes => ({ file, filename }) => {
    if (!fileTypes.includes(file.type)) {
      message.error(
        `Invalid file type. Must be one of: ${fileTypes.join(', ')}.`
      );
      return false;
    }
    this.setState({ loading: true });

    try {
      util.upload(
        file,
        `${this.props.uid}/${util.randomString()}_${filename}`,
        (error, url) => {
          if (error) {
            message.error(error.message);
            return;
          }
          console.log('got download url!');
          this.props.setValue(url);
        }
      );
    } catch (error) {
      message.error(error.message);
      return;
    }
    this.setState({ loading: false });
    return {
      abort: () => {
        console.log('aborting file upload.');
      },
    };
  };

  render() {
    const { type } = this.props;

    let isImage = type === 'image';
    let extensions = isImage
      ? ['image/jpeg', 'image/jpg', 'image/png']
      : ['application/pdf'];
    let imgPreview = null;
    let filePreview = null;
    if (this.props.value) {
      if (isImage) {
        imgPreview = (
          <img
            key="img"
            className="thumbnail"
            src={this.props.value}
            alt="avatar"
          />
        );
      } else {
        filePreview = (
          <Row>
            <a
              href={this.props.value}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="primary" value="small">
                View file
              </Button>
            </a>
          </Row>
        );
      }
    }

    let element = filePreview || (
      <Upload
        key="upload"
        customRequest={this.uploadFile(extensions)}
        showUploadList={false}
      >
        {imgPreview || (
          <div className="upload">
            <Icon type={this.state.loading ? 'loading' : 'upload'} />
            <span className="ant-upload-text">
              {this.state.loading ? 'Uploading...' : 'Choose file'}
            </span>
          </div>
        )}
      </Upload>
    );
    return [
      element,
      <Modal
        title="Uploading..."
        key="modal"
        visible={this.state.loading}
        footer={null}
        onCancel={() => {
          this.setState({ loading: false });
        }}
      >
        <Spin />
      </Modal>,
    ];
  }
}

const mstp = state => ({
  uid: state.uid,
});
export default connect(mstp)(FireUpload);
