import React from 'react';
import { Menu, Dropdown } from 'antd';

import IconCircle from './IconCircle';
import * as globals from '../globals';

const UserMenu = props => {
  const menu = (
    <Menu>
      <Menu.Item>
        <p className="textbutton" onClick={() => globals.auth.signOut()}>
          Log out
        </p>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <IconCircle icon="setting" />
    </Dropdown>
  );
};

export default UserMenu;
